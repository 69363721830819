import { Box, Paper, Theme, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import BookingTable from "../../components/Certificates/BookingTable";
import LehrgangSearchForm from "../../components/Certificates/LehrgangSearchForm";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  searchForm: {
    padding: theme.spacing(2),
  }
}));

const Certificates: FunctionComponent = () => {

  const [lehrgangId, setLehrgangId] = useState<string>('');

  const { t } = useTranslation();

  const classes = useStyles();

  const handleFormSubmit = async (lehrgangId: string) => {
    setLehrgangId(lehrgangId);
  }

  return <>
    <Box mt={2}>
      <Typography variant="h1" component="h2" className="m-4">
        {t('pages.certificates.certificatesTitle')}
      </Typography>
    </Box>
    <Paper className={classes.searchForm}>
      <LehrgangSearchForm handleSubmit={handleFormSubmit} />
    </Paper>

    {lehrgangId && <BookingTable lehrgangId={lehrgangId} />}
  </>
}

export default Certificates;