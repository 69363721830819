import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import LogErrorReasons from "./LogErrorReasons"
import LogItem from "./LogItem"

interface ILogMessageProps {
  logType: string,
  params?: Record<string, unknown>,
}

const LogMessage: FunctionComponent<ILogMessageProps> = ({
  logType,
  params,
}) => {

  const { t } = useTranslation();

  switch(logType) {
    case 'event.processing.succeeded':
      return <LogItem
        logLevel="info"
        message={t('pages.certificates.statusMessages.event-processing-succeeded', params)} />

    case 'certificate.create.succeeded':
      return <LogItem 
        logLevel="info"
        message={t('pages.certificates.statusMessages.certificate-create-succeeded', params)} />

    case 'event.processing.failed':
      return <LogItem 
        logLevel="error"
        message={t('pages.certificates.statusMessages.event-processing-failed')}>
        <LogErrorReasons reasonIds={params?.reasonIds as string[] || []} />
      </LogItem>

    case 'event.processing.skipped':
      return <LogItem 
        logLevel="warning"
        message={t('pages.certificates.statusMessages.event-processing-skipped')}>
        <LogErrorReasons reasonIds={params?.reasonIds as string [] || []} productData={params?.productData as Record<string, unknown>} />
      </LogItem>

    case 'certificate.updated':
      return <LogItem 
        logLevel="error"
        message={t('pages.certificates.statusMessages.certificate-updated', params)}>
        <LogErrorReasons reasonIds={params?.reasonIds as string[] || []} />
      </LogItem>
  }

  return null;
}

export default LogMessage;
