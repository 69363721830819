import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const ROOT_KEY = 'bookings';

const bookingKeys = {
  lehrgangBookings: (lehrgangId: string) => [ROOT_KEY, 'lehrgang', lehrgangId] as const,
};

export const useLehrgangBookingsQuery = (getIdToken: () => Promise<string>, lehrgangId: string) => {
  return useQuery({
    queryKey: bookingKeys.lehrgangBookings(lehrgangId),
    queryFn: async () => await getApiService(getIdToken).getLehrgangBookings(lehrgangId),
  });
};
