import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FunctionComponent, useState } from "react";

interface IDialogConfirmationProps {
  cancelText?: string
  confirmText?: string
  dialogContent?: string
  dialogTitle: string
  confirmCallback: () => Promise<void>
}

const useStyles = makeStyles(() => ({
  progress: {
    textAlign: 'center',
  },
  button: {
    minWidth: 0
  }
}))

const DialogConfirmation: FunctionComponent<React.PropsWithChildren<IDialogConfirmationProps>> = ({
  cancelText,
  children,
  confirmText,
  dialogContent,
  dialogTitle,
  confirmCallback
}) => {

  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleClose = () => setShowDialog(false);


  const handleConfirm = async () => {
    setProcessing(true);
    await confirmCallback();
    setShowDialog(false);
    setProcessing(false);
  }

  return <>
    <Button color="secondary" size="small" onClick={() => setShowDialog(true)} className={classes.button}>
        { children }
      </Button>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ dialogTitle }</DialogTitle>
        <DialogContent>
            { processing
              ? <div className={classes.progress}><CircularProgress /></div>
              : dialogContent
            }
        </DialogContent>
        { !processing && <DialogActions>
            <Button onClick={handleClose} color="primary">
              { cancelText }
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              { confirmText }
            </Button>
          </DialogActions>
        }
      </Dialog>
    </>
}

export default DialogConfirmation;
