type EnvironmentSpecificConfig = {
  API_URL: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8001/dev',
};
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://0ivbh1vus3-vpce-0cc31765e64fffec7.execute-api.eu-central-1.amazonaws.com/test',
};

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://p2na8pks8l-vpce-04301e6b4f30d6e3d.execute-api.eu-central-1.amazonaws.com/prod',
};

const env = process.env.REACT_APP_STAGE ?? 'dev';

const mergedConfig = {
  ...(env === 'dev' && dev),
  ...(env === 'test' && test),
  ...(env === 'prod' && prod),
  STANDALONE: env === 'dev',
};

export const MICROFRONTEND_ID = 'certificates';

export default mergedConfig;
