import { Box, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface ILehrgangSearchFormProps {
  handleSubmit: (lehrgangId: string) => void
}

const useStyles = makeStyles(() => ({
  searchButton: {
    marginTop: '12px',
    marginLeft: 10,
  }
}));

const LehrgangSearchForm: FunctionComponent<ILehrgangSearchFormProps> = ({ handleSubmit }) => {

  const [lehrgangId, setLehrgangId] = useState('');

  const { t } = useTranslation();

  const classes = useStyles();
  
  return <Box
    component="form"
    sx={{
      '& > :not(style)': { m: 1 },
    }}
    noValidate
    autoComplete="off"
    justifyContent="flex-end"
  >
    <TextField 
      required
      id="va-id-input"
      label="Lehrgangs-ID"
      onChange={(e) => setLehrgangId(e.target.value)}
      size="small"
      margin="dense"
    />
    <Button
      className={classes.searchButton}
      variant="contained"
      color="primary"
      onClick={() => handleSubmit(lehrgangId)}
    >
      { t('pages.certificates.eventSearch.search') }
    </Button>
  </Box>
}

export default LehrgangSearchForm;
