import { FunctionComponent } from "react"

interface ILogItemProps {
  logLevel: string,
  message: string,
}

const LogItem: FunctionComponent<React.PropsWithChildren<ILogItemProps>> = ({
  message,
  logLevel: _logLevel,
  children,
}
) => {
  return <>
    {message}
    {children}
  </>
}

export default LogItem;
