import React, { FunctionComponent, useState } from 'react';
import { Alert, AppBar, Box, Tab, Tabs, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EventCheck from './EventCheck/EventCheck';
import TabPanel from './TabPanel';
import TBPreview from './TBPreview';
import ColorPalette from '@packages/lighthouse-theme/src/ColorPalette';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: ColorPalette.monochrome.grey10,
    '& .MuiTab-root': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    }
  },
  tabContent: {
    padding: theme.spacing(2)
  },
  notification: {
    marginBottom: theme.spacing(2)
  }
}))

const TBCockpitPage: FunctionComponent = () => {

  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <>
      <Box mt={2}>
          <Typography variant="h1" component="h2" className="m-4">
            {t('pages.certificates.title')}
          </Typography>
        </Box>
      <Alert className={classes.notification} severity="info"><div dangerouslySetInnerHTML={{ __html: t('pages.certificates.notification') }} /></Alert>
      <Box className={classes.tabs}>
        <AppBar position="static" color="default" elevation={1}>
          
          <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className={classes.tabs}
          >
              <Tab
                  label={t('pages.certificates.tabHeaders.eventSearch')}
                  id="scrollable-auto-tab-0"
                  aria-controls="scrollable-auto-tabpanel-0"
              />
              <Tab
                  label={t('pages.certificates.tabHeaders.preview')}
                  id="scrollable-auto-tab-1"
                  aria-controls="scrollable-auto-tabpanel-1"
              />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <EventCheck />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <TBPreview />
        </TabPanel>
      </Box>
    </>
  );
};

export default TBCockpitPage;
