import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Alert, Button, FormControl, FormControlLabel, FormLabel, LinearProgress, Paper, Radio, RadioGroup, TextField, Theme, Typography, Box } from '@mui/material'
import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import MicroFrontendContext from '../MicroFrontendContext';
import { getApiService } from '../../api/api-request';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },

  }),
);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const TBPreview: FunctionComponent = () => {



  const classes = useStyles();
  const { t } = useTranslation();

  type Accordions = '' | 'live' | 'manual';

  const [bookingNumber, setBookingNumber] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [pdfResponse, setPdfResponse] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false);
  const [tbType, setTbType] = useState('CONFIRMATION_OF_PARTICIPATION');
  const [activeAccordion, setActiveAccordion] = useState<Accordions>('live');

  const { getIdToken } = useContext(MicroFrontendContext);

  const handleBookingNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookingNumber(event.target.value);
  }

  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }

  const handleTbTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTbType(event.target.value as string);
  };

  const handleBookingNumberSubmit = async () => {
    if (bookingNumber) {
      const apiService = getApiService(getIdToken);
      try {
        setLoading(true)
        setError(false)
        setPdfResponse('')
        const previewData = await apiService.getCertificationPreview(bookingNumber)
        setLoading(false)
        setPdfResponse(previewData);
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }
  }

  const handleSubmit = async () => {
    if (content && tbType) {
      try {
        const apiService = getApiService(getIdToken);
        setLoading(true)
        setError(false)
        setPdfResponse('')
        const previewData = await apiService.getCertificatePreview(content, tbType);
        setLoading(false)
        setPdfResponse(previewData);
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleAccordionChange = (panel: Accordions) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setActiveAccordion(newExpanded ? panel : '');
  };

  return <Paper className={classes.root}>
    <>
      <Accordion square expanded={activeAccordion === 'live'} onChange={handleAccordionChange('live')}>
        <AccordionSummary id="preview-form-live-header" expandIcon={<ExpandMoreIcon />}>
          <Typography>Live Vorschau</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off"
            justifyContent="flex-end"
          >
            <TextField
              key="input-booking-number"
              required
              label="Buchnungsnummer"
              onChange={handleBookingNumberChange}
              value={bookingNumber}
              size="small"
              margin="dense"
            />

            <Button
              key="submit-booking-number"
              variant="contained"
              color="primary"
              onClick={handleBookingNumberSubmit}
              disabled={!bookingNumber.trim()}
            >
              {t('pages.certificates.preview.showPreview')}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
    <Accordion square expanded={activeAccordion === 'manual'} onChange={handleAccordionChange('manual')}>
      <AccordionSummary aria-controls="preview-form-manual-content" id="preview-form-manual-header" expandIcon={<ExpandMoreIcon />}>
        <Typography>Manuelle Vorschau</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <FormControl>
            <FormLabel component="legend">TB-Typ</FormLabel>
            <RadioGroup aria-label="gender" name="tbType" value={tbType} onChange={handleTbTypeChange}>
              <FormControlLabel value="CONFIRMATION_OF_PARTICIPATION" control={<Radio />} label="Standard" />
              <FormControlLabel value="CERTIFICATE_GM" control={<Radio />} label="General Management" />
              <FormControlLabel value="CERTIFICATE_QP" control={<Radio />} label="Qualifizierungsprogramm" />
              <FormControlLabel value="CERTIFICATE_BL" control={<Radio />} label="Blended Learning" />
            </RadioGroup>
          </FormControl>
          <TextField
            required
            id="content-input"
            label="Inhalt"
            onChange={handleContentChange}
            value={content}
            variant="filled"
            minRows={5}
            maxRows={5}
            style={{ marginRight: 30, width: 400, overflow: 'scroll' }}
            onCopy={(e) => { e.preventDefault(); return false; }}
            multiline
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!content.trim() || !tbType}
            style={{ height: '100%', marginLeft: '10' }}
          >
            {t('pages.certificates.preview.showPreview')}
          </Button>
        </>
      </AccordionDetails>
    </Accordion>

    {loading &&
      <LinearProgress />
    }
    {error &&
      <Alert severity={"info"}>{t('pages.certificates.preview.tbNotFound')}</Alert>
    }
    {pdfResponse && !error &&
      <embed src={`data:application/pdf;base64,${pdfResponse}#view=fitH`} type="application/pdf" width={'100%'} height={800} />
    }
  </Paper>
}

export default TBPreview
