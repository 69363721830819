export const i18n_de = {
  pages: {
    certificates: {
      title: 'Teilnahmebestätigungen',
      certificatesTitle: 'Zertifikate',
      notification:
        'Informationen zu TBs, sowie Regeln zur Erstellung findest du <a href="https://mywiki.grp.haufemg.com/display/TEAMAKKS/LU-Cockpit#LUCockpit-TBCockpit/DigitaleTBs" target="_blank" rel="noreferrer">hier im wiki',
      errorMessages: {
        'content-missing': 'TB Inhalt fehlt',
        'invalid-type': 'Ungültiges Typ "{{type}}" - muss auf "STANDARD" gesetzt sein',
        'title-missing': 'TB Titel fehlt',
        'no-product-data': 'Keine Produktdaten verfügbar/publiziert',
        'no-bookings': 'Keine Buchungen vorhanden',
      },
      statusMessages: {
        'event-processing-succeeded': 'TB-Texte erstellt',
        'certificate-create-succeeded': 'TB erstellt für: {{firstName}} {{lastName}}',
        'event-processing-failed': 'TB-Text-Erstellung fehlgeschlagen',
        'event-processing-skipped': 'Kriterien zum Erstellen des TB-Texts nicht erfüllt:',
        'certificate-create-failed': 'Erstellung der TB für {{firstName}} {{lastName}} fehlgeschlagen',
        'certificate-updated':
          'Namensänderung: {{oldValues.firstName}} {{oldValues.lastName}} -> {{newValues.firstName}} {{newValues.lastName}}',
      },
      tabHeaders: {
        eventSearch: 'VA-Suche',
        preview: 'TB Vorschau',
        information: 'Information',
      },
      eventSearch: {
        search: 'Suchen',
        certificateTableHeader: 'Teilnahmebestätigungen',
        noCertificates: 'Keine digitalen TBs vorhanden',
        noLogs: 'Keine Elemente vorhanden',
        changeName: 'Namensänderung',
        STATUS_ISSUED: 'Ausgestellt',
        STATUS_NOT_AVAILABLE: 'Nicht Anforderbar',
        STATUS_REQUESTABLE: 'Anforderbar',
        productTbVerified: 'TBs können für das Produkt ausgestellt werden',
        generateContent: 'TB-Texte laden',
        generateContentPopup: {
          description:
            'Achtung: Für diese Veranstaltung wurde zum Zeitpunkt der Veranstaltung kein TB-Text (aus den Produktdaten) gespeichert.<br/>Die TB-Texte können sich bis jetzt verändert haben.<br/>Bei Klick auf "JA" wird der jetzt aktuelle TB-Text aus den Produktdaten gezogen.',
          save: 'Ja',
          close: 'Nein',
        },
        missingTbContent: 'Fehlende TB-Vorbereitung',
        noProductData: 'Keine Produkt-Daten verfügbar',
        noEventData: 'Keine VA-Daten in Viva verfügbar',
        recreateCausedName: 'Namensänderung',
        deleteTB: 'TB löschen',
        generateTB: 'TB generieren',
        generationFailed: 'TB konnte nicht aktualisiert werden',
        save: 'Speichern',
        errors: {
          certificates: 'Fehler beim Laden der TBs',
          logs: 'Fehler beim Laden der Logs',
          status: 'Fehler beim Laden des Status',
        },
        statusLabels: {
          SUCCESS: 'OK',
          WARNING: 'Warnung',
          ERROR: 'TB kann nicht erstellt werden',
        },
        tableHeaders: {
          name: 'Name',
          createDate: 'Erstellt am',
          certificateRequestStatus: 'Ausstellstatus',
          date: 'Datum',
          actions: 'Aktionen',
          passed: 'Check',
          message: 'Nachricht',
          updateDate: 'Geändert am',
          infos: 'Informationen',
          requestStatus: 'Status abfragen',
          productData: 'Produkt Daten',
        },
      },
      editDialog: {
        cancel: 'Abbrechen',
      },
      logs: {
        tableHeader: 'Ereignisse',
        alertDeleteFailed: 'Fehler beim Löschen des Eintrags',
        alertDeleteSucceeded: 'Eintrag gelöscht',
        reallyDelete: 'Eintrag wirklich löschen?',
      },
      preview: {
        showPreview: 'Vorschau anzeigen',
        tbNotFound: 'Keine TB gefunden',
      },
      createCertificate: {
        deleteCertificate: {
          error: 'Beim Löschen des Zertifikats ist ein Fehler aufgetreten',
          success: 'Zertifikat wurde erfolgreich gelöscht',
        },
        createCertificate: {
          error: 'Beim Erstellen des Zertifikats ist ein Fehler aufgetreten',
          success: 'Zertifikat wurde erfolgreich ausgestellt',
        },
        bookingTable: {
          headers: {
            userId: 'hmguserid',
            issuedAt: 'Ausstellungsdatum',
            updatedAt: 'Letzte Aktualisierung',
            actions: 'Aktionen',
          },
          filters: {
            header: 'Filter',
            status: {
              label: 'Status',
              showAll: 'Alle anzeigen',
              issued: 'Ausgestellt',
              pending: 'Noch nicht ausgestellt',
            },
          },
          createCertificate: 'Zertifikat ausstellen',
          uploadCertificate: 'Zertifikat upload',
          infoManualUpload: 'Dieses Zertifikat wurde als PDF-Datei hochgeladen und kann nicht editiert werden.',
          download: 'Download',
          edit: 'Bearbeiten',
          delete: 'Zertifikat löschen',
          cancel: 'Abbrechen',
        },
      },
      createCertificateForm: {
        header: 'Zertifikat ausstellen',
        preview: {
          header: 'Vorschau',
          backButtonLabel: 'zurück',
        },
        generatingPreview: 'Vorschau wird erstellt',
        labels: {
          issueDate: 'Ausstellungsdatum',
          evaluationText: 'Bewertung (Text)',
          score: 'Erreichte Punkte',
          evaluationRanges: 'Bewertungsschlüssel',
          cooperationPartner: 'Kooperationspartner',
          noCooperationPartner: 'Kein Kooperationspartner',
          title: 'Title',
          firstName: 'Vorname',
          lastName: 'Nachname',
        },
        cooperationPartners: {
          HDWM: 'Hochschule der Wirtschaft für Management (HdWM) Mannheim',
          OTHAW: 'Ostbayerische Technische Hochschule Amberg-Weiden',
          THD: 'Technische Hochschule Deggendorf',
        },
        buttons: {
          preview: 'Vorschau anzeigen',
          create: 'Zertifikat ausstellen',
          update: 'Zertifikat aktualisieren',
          cancel: 'Abbrechen',
        },
        error: {
          title: 'Fehler beim Erstellen des Zertifikats',
          missingProductData:
            'Das Zertifikat konnte nicht erstellt werden, da nicht mehr zu allen Lehrgangsmodulen die Produktdaten verfügbar sind.',
          closeButtonLabel: 'Schliessen',
        },
      },
      uploadCertificateForm: {
        header: 'Zertifikat Upload',
        uploadFile: 'Zertifikat speichern',
        uploadLabel: 'Bitte Zertifikats-PDF-Datei auswählen',
      },
    },
  },
};
