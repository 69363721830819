import { AlertColor } from '@mui/material';
import { createContext } from 'react';

export interface IAlert {
  type: AlertColor;
  i18nKey: string;
  error?: Error;
  highPriority?: boolean;
  horizontalPosition?: 'center' | 'left' | 'right';
  autoHideDuration?: number;
}

export interface IAlertContext {
  enqueueAlert: (alert: IAlert) => void;
}

const initialProps: IAlertContext = {
  // eslint-disable-next-line no-console
  enqueueAlert: () => {},
};

export const AlertContext = createContext<IAlertContext>(initialProps);
