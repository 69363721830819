import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ILogErrorReasonsProps {
  reasonIds: string[]
  productData?: Record<string, unknown>
}

const LogErrorReasons: FunctionComponent<ILogErrorReasonsProps> = ({productData, reasonIds}) => {
  const { t } = useTranslation();
  return (
    <ul>
      {reasonIds.map((reasonId, i) => {
        const translationKey = `pages.certificates.errorMessages.${reasonId}`
        return <li key={`reason-${i}`}>{t(translationKey, productData)}</li> 
      })}
    </ul>
  )
}

export default LogErrorReasons;
