import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { format } from "date-fns";
import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';
import LogMessage from "../LogMessage";
import { ICertificationLogItemResponse } from "@shared/interfaces";

interface ILogsTableProps {
  logs: ICertificationLogItemResponse[],
}

const LogsTable: FunctionComponent<ILogsTableProps> = ({
  logs
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
    <Table size="small" aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell
            key="certificates-issue-date"
            align="left"
            width="200"
          >
            {t('pages.certificates.eventSearch.tableHeaders.date')}
          </TableCell>
          <TableCell
            key="certificates-actions"
            align="left"
            style={{ minWidth: "250" }}
          >
            {t('pages.certificates.eventSearch.tableHeaders.message')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((log, i: number) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={`tb-logs-row-${i}`}>
              <TableCell key={`logs-row-${i}-date`} width="200">
                {log.timestamp ? format(new Date(log.timestamp), 'dd.MM.yyyy HH:mm:ss') : ''}
              </TableCell>
              <TableCell key={`certificates-row-${i}-message`}>
                <LogMessage logType={log.logType} params={log.data} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  );
}

export default LogsTable;
