import './i18n';
import '@packages/lighthouse-theme/src/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import MicroFrontendContext from './components/MicroFrontendContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AlertSnackBar } from './components/AlertSnackbar/AlertSnackbar';
import TBCockpitPage from './components/TBCockpit/TBCockpitPage';
import Certificates from './components/TBCockpit/Certificates';
import { IMicrofrontendProps, Microfrontend } from '@hmg-aka-lu/cockpit-microfrontend';
import { FunctionComponent } from 'react';
import { createRoot } from 'react-dom/client';

declare global {
  interface Window {
    [MICROFRONTEND_ID]: Microfrontend;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CertificatesMicroFrontend: FunctionComponent<IMicrofrontendProps> = ({
  getIdToken,
  permissions,
}) => {

  const queryClient = new QueryClient();

  return (
    <LightHouseThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MicroFrontendContext.Provider value={{ getIdToken, permissions }}>
          <BrowserRouter>
            <Routes>
              <Route path={`/${MICROFRONTEND_ID}/search`} element={<TBCockpitPage />} />
              <Route path={`/${MICROFRONTEND_ID}/certificates`} element={<Certificates />} />
              <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/search`} />} />
            </Routes>
          </BrowserRouter>
          <AlertSnackBar />
        </MicroFrontendContext.Provider>
      </QueryClientProvider>
    </LightHouseThemeProvider>
  );
};

const microFrontendInstance: Microfrontend = (containerId = 'root', props) => {
  const rootElement = document.getElementById(containerId);
  if (!rootElement) {
    throw new Error('DOM root node not found');
  }

  return {
    render: () => {
      createRoot(rootElement).render(<CertificatesMicroFrontend getIdToken={props.getIdToken} permissions={props.permissions} />);
    },
  };
};

window.certificates = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance('root', {
    permissions: ['Superuser'],
    getIdToken: async () =>'',
  });

  microFrontend.render();
}
