import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, LinearProgress, TextField, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles';
import { ICertificationTableItem, IIssuedCertificationTableItem } from '@shared/interfaces';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MicroFrontendContext from '../MicroFrontendContext';
import { getApiService } from '../../api/api-request';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(2),
        display: 'block'
      },
      '& .MuiInputBase-root': {
        width: '100%',
        maxWidth: '500px'
      },
      '& .MuiButtonBase-root + *': {
        margin: theme.spacing(1)
      }
    },
    formContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    formEdit: {
      '& .MuiFormControl-root': {
        maxWidth: 300
      }
    },
  }),

);

interface IRecreateNameProps {
  vaId: string
  certificationItem: ICertificationTableItem & IIssuedCertificationTableItem
  onSaveCallback: (certificationItem: ICertificationTableItem) => void
  onCloseCallback: () => void

}

const TBRecreateName: FunctionComponent<IRecreateNameProps> = ({
  vaId,
  certificationItem,
  onSaveCallback,
  onCloseCallback,
}) => {


  const handleClose = () => {
    resetState();
    onCloseCallback();
  };

  const classes = useStyles();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>(certificationItem.firstName || '')
  const [lastName, setLastName] = useState<string>(certificationItem.lastName || '')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState(false);

  const { getIdToken } = useContext(MicroFrontendContext);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  }
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  }

  const resetState = () => {
    setError(false)
    setFirstName('');
    setLastName('');
  }

  const handleSubmit = async () => {
    if (vaId && certificationItem.userid) {
      setLoading(true)
      try {
        const apiService = getApiService(getIdToken);
        await apiService.updateUserEventCertificateData(certificationItem.userid, vaId, {
          firstName,
          lastName,
        });

        setLoading(false);
        resetState();
        onSaveCallback({
          ...certificationItem, name: `${firstName} ${lastName} (${certificationItem.userid})`,
          firstName: firstName,
          lastName: lastName,
          updateAt: new Date().toISOString()
        })
        handleClose();
      }
      catch (error) {
        setError(true)
      }
      finally {
        setLoading(false)
      }
    }
  }

  return <Dialog
    open={true}
    onClose={handleClose}
    aria-labelledby="edit-survey-link-dialog-title"
    aria-describedby="edit-survey-link-dialog-description"
  >
    <DialogTitle id="edit-survey-link-dialog">{t('pages.certificates.eventSearch.recreateCausedName')}</DialogTitle>
    <DialogContent>
      <Box component="div" display={'flex'} flexDirection="column" className={classes.formEdit}>
        <FormControl>
          <TextField
            required
            id="firstname-number-input"
            label="Vorname"
            onChange={handleFirstNameChange}
            defaultValue={firstName}
            size="small"
            margin="dense"
          />
        </FormControl>
        <br />
        <FormControl>
          <TextField
            required
            id="lastname-number-input"
            label="Nachname"
            onChange={handleLastNameChange}
            defaultValue={lastName}
            size="small"
            margin="dense"
          />
        </FormControl>
        <br />
      </Box>
    </DialogContent>
    {<DialogActions>
      <Button onClick={handleClose} color="primary" disabled={loading}>
        {t('pages.certificates.editDialog.cancel')}
      </Button>
      <Button
        autoFocus
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
      >
        {t('pages.certificates.eventSearch.save')}
      </Button>
    </DialogActions>
    }
    <br />
    {loading &&
      <LinearProgress />
    }
    {error &&
      <Alert severity={"error"}>{t('pages.certificates.eventSearch.generationFailed')}</Alert>
    }
  </Dialog>



}



export default TBRecreateName
