import { 
  Alert,
  Box, 
  Button, 
  Chip, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  LinearProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Tooltip,
  useTheme,
} from "@mui/material"
import { FunctionComponent, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from "@mui/styles";
import { ICertificationStatusDisplayContent } from "@shared/interfaces";

interface ICertificateStatusProps {
  certificationStatus: ICertificationStatusDisplayContent | null,
  generateCertificationContent: () => void
}

const useStyles = makeStyles(() => ({
  table: {
    marginTop: '1.5rem',
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0
    }
  }
}));

const CertificateStatus: FunctionComponent<ICertificateStatusProps> = (
  { certificationStatus, generateCertificationContent }
) => {

  const [generating, setGenerating] = useState(false);
  const [displayLoadPopup, setDisplayLoadPopup] = useState(false)

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const handlePopupClose = () => {
    setDisplayLoadPopup(false)
  }

  const handleSave = () => {
    setGenerating(true);
    try {
      generateCertificationContent()    
    } catch (error) {
      
    } finally {
      setGenerating(false);
    }
  }

  const handleLoadContent = () => {
    setDisplayLoadPopup(true)
  }

  const getLabelColor = () => {
    const statusLabel = certificationStatus?.statusType;
    switch (statusLabel) {
      case 'ERROR':
        return theme.palette.error.main;
      case 'WARNING':
        return theme.palette.warning.main;
      case 'SUCCESS':
        return theme.palette.success.main;
      default:
        return theme.palette.warning.main;
    }
  }

  return (
    certificationStatus?.statusType !== 'FATAL_ERROR' ?
      <>
        <Box display={'flex'} alignItems={'center'}>
          <h2>Status</h2>
          {certificationStatus?.statusType &&
            <Chip label={t(`pages.certificates.eventSearch.statusLabels.${certificationStatus.statusType}`)} style={{
              backgroundColor: getLabelColor(),
              marginLeft: theme.spacing(1),
              color: theme.palette.common.white
            }} />
          }
        </Box>
        <Box>
          {certificationStatus?.statusLabel}
          {certificationStatus?.showContentCreateButton &&
            <>
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadContent}
                >
                  {t('pages.certificates.eventSearch.generateContent')}
                </Button>
              </Box>
              <Dialog fullWidth open={displayLoadPopup} onClose={handlePopupClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t('pages.certificates.eventSearch.generateContent')}</DialogTitle>
                <DialogContent>
                  <Alert severity="warning">
                    <Trans i18nKey={'pages.certificates.eventSearch.generateContentPopup.description'} />
                  </Alert>
                </DialogContent>
                <DialogActions style={{padding: '16px 24px'}}>
                  <Button disabled={generating} onClick={handlePopupClose} color="primary" variant="outlined">
                    {t('pages.certificates.eventSearch.generateContentPopup.close')}
                  </Button>
                  <Button disabled={generating} onClick={handleSave} color="primary" variant="contained">
                    {t('pages.certificates.eventSearch.generateContentPopup.save')}
                  </Button>
                </DialogActions>
                {generating && <LinearProgress />}
              </Dialog>
            </>
          }
        </Box>

        {certificationStatus?.statusTable &&
          <Table size="small" aria-label="sticky table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  key="certificates-status-id"
                  align="left"
                  width="200"
                >
                  {t('pages.certificates.eventSearch.tableHeaders.name')}
                </TableCell>
                <TableCell
                  key="certificates-status-passed"
                  align="left"
                  style={{ minWidth: "250" }}
                >
                  {t('pages.certificates.eventSearch.tableHeaders.passed')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certificationStatus.statusTable.map((criteria, i: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`tb-logs-row-${i + 1}`}>

                    <TableCell key={`status-row-${i + 1}-name`} width="200">
                      {criteria.label}
                    </TableCell>
                    <TableCell key={`status-row-${i + 1}-passed`}>
                      <Tooltip title={criteria.info}>
                        <InfoIcon fontSize="small" style={{ marginLeft: '.5rem', marginBottom: '-.25rem' }} color="error" />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>}
      </> : <></>
  );
}

export default CertificateStatus;
