import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const ROOT_KEY = 'users';

const userKeys = {
  userData: (userId: string) => [ROOT_KEY, userId] as const,
};

export const useUserDataQuery = (getIdToken: () => Promise<string>, userId: string) => {
  return useQuery({
    queryKey: userKeys.userData(userId),
    queryFn: () => getApiService(getIdToken).getUserData(userId),
    enabled: userId.length > 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
