import { Box, Button, Grid, Input, InputLabel, LinearProgress, Skeleton, Typography } from "@mui/material";
import { ChangeEvent, FunctionComponent, useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useCreateCertificateUploadUrlMutation, useCreateUploadedCertificateMutation } from "../../queries/certificates";
import MicroFrontendContext from "../MicroFrontendContext";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useUserDataQuery } from "../../queries/users";
import { useQueryClient } from "@tanstack/react-query";
import { AlertContext } from "../AlertSnackbar/AlertContext";

interface IFileInput {
  file: File;
  path: string;
}

const ALLOWED_MIME_TYPES = [
  'application/pdf'
];

interface IUploadCertificateProps {
  hmguserid: string;
  lehrgangId: string;
  onCancelCallback: () => void;
}

export const UploadCertificateForm: FunctionComponent<IUploadCertificateProps> = ({ hmguserid, lehrgangId, onCancelCallback }) => {

  const [file, setFile] = useState<IFileInput>();
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();

  const { getIdToken } = useContext(MicroFrontendContext);
  const { data: userData } = useUserDataQuery(getIdToken, hmguserid);
  const {
    mutateAsync: createCertificateUploadUrl,
  } = useCreateCertificateUploadUrlMutation(getIdToken);

  const queryClient = useQueryClient();
  const alertContext = useContext(AlertContext);

  const onError = () => {
    alertContext.enqueueAlert({
      type: 'error',
      i18nKey: 'pages.certificates.createCertificate.createCertificate.error',
    });
    onCancelCallback();
  };

  const {
    mutate: createCertificate,
  } = useCreateUploadedCertificateMutation(getIdToken, queryClient, alertContext)

  const uploadFile = async (file: File, url: string) => {
    const res = await fetch(url, {
      method: 'PUT',
      body: file,
    });

    return res.ok;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      if (ALLOWED_MIME_TYPES.includes(file.type)) {
        setFile({
          file,
          path: event.currentTarget.value || '',
        });
      } else {
        alert(t('settings.assets.invalidFileType'));
      }
    } else {
      setFile(undefined);
    }
  };

  const handleUploadClick = async () => {
    setIsUploading(true);
    const result = await createCertificateUploadUrl();
    try {
      if (file) {
        const fileUploaded = await uploadFile(file.file, result.url);
        if (fileUploaded) {
          setFile(undefined);
          const fileKey = new URL(result.url).pathname.substring(1)

          createCertificate({
            fileKey,
            hmguserid,
            lehrgangId,
          }, {
            onSuccess: () => onCancelCallback()
          })
        } else {
          throw new Error('File-Upload failed');
        }
      }
    } catch (error) {
      onError();
    } finally {
      setIsUploading(false);
    }
  };

  return <>
    <Typography variant="h5">
      {t('pages.certificates.uploadCertificateForm.header')}
    </Typography>
    <Grid container spacing={2}>

      <Grid container item xs={12} data-testid={userData ? 'user-details-loaded' : 'user-details-loading'}>
        <AccountBoxIcon color="disabled" /> {userData ? `${userData.title ?? ''} ${userData.firstname} ${userData.lastname}`.trim() : <Skeleton width={200} />} - {hmguserid}
      </Grid>
    </Grid>
    <Box sx={{ mt: 3 }}>
      <InputLabel>{t('pages.certificates.uploadCertificateForm.uploadLabel')}</InputLabel>
      <Input
        type={'file'}
        onChange={handleInputChange}
        value={file ? file.path : ''}
        inputProps={{ accept: 'application/pdf', 'data-testid': "upload-certificate-file-input" }}
      />
    </Box>
    <Box sx={{ mt: 3 }}>
      {isUploading && <LinearProgress />}
      <Button
        data-testid="upload-certificate-button"
        variant={'contained'}
        disabled={!file || isUploading}
        color={'primary'}
        onClick={handleUploadClick}
      >
        <Trans i18nKey={'pages.certificates.uploadCertificateForm.uploadFile'} />
      </Button>
    </Box>
  </>;
}