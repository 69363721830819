import { Alert, Snackbar } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { AlertContext, IAlert } from './AlertContext';
import { Trans } from 'react-i18next';

export const AlertSnackBar: FunctionComponent = () => {
  const [alertQueue, setAlertQueue] = useState<IAlert[]>([]);
  const [currentAlert, setCurrentAlert] = useState<IAlert>();
  const alertContext = useContext(AlertContext);

  alertContext.enqueueAlert = (alert) => {
    if (alert.highPriority) {
      setAlertQueue((queue) => [alert, ...queue]);
    } else {
      setAlertQueue((queue) => [...queue, alert]);
    }
  };

  useEffect(() => {
    if (alertQueue.length > 0 && !currentAlert) {
      setCurrentAlert(alertQueue[0]);
      setAlertQueue((queue) => queue.slice(1));
    }
  }, [alertQueue, currentAlert]);

  return (
    <>
      {currentAlert && (
        <Snackbar
          open={currentAlert !== undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: currentAlert.horizontalPosition ?? 'left',
          }}
          autoHideDuration={currentAlert.autoHideDuration ? currentAlert.autoHideDuration : 3000}
          onClose={(event, reason) => {
            if (reason === 'timeout') {
              setCurrentAlert(undefined);
            }
          }}
        >
          <Alert severity={currentAlert.type}>
            <Trans i18nKey={currentAlert.i18nKey} />
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
